/* eslint-disable */
import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import Header from "../Layout/Header";
import cookie from "react-cookies";
class TierBenefits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_page: "Subscription",
    };
    if (cookie.load("UserId") === undefined) {
      props.history.push("/");
    }
  }

  render() {
    return (
      <div className="main-div">
        <Header mainpagestate={this.state} prntPagePrps={this.props} />

        <div className="rel">
          <div className="container">
            <div className="s-calender-list">
              <ul>
                <li>
                  <Link to={"/subscription/product"}>Product Subscription</Link>
                </li>
                <li>
                  <Link to={"/subscription/store"}>Store Subscription</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var staticblocksArr = Array();
  if (Object.keys(state.staticblocks).length > 0) {
    if (state.staticblocks[0].status === "ok") {
      staticblocksArr = state.staticblocks[0].result_set;
    }
  }
  return {
    staticblocks: staticblocksArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return "";
};
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(TierBenefits));
